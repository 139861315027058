import { motion } from "framer-motion";
import { HiCheck, HiChevronRight, HiOutlineInformationCircle } from "react-icons/hi";
import firstImg from "../assets/images/product one.png";
import secondImg from "../assets/images/product two.jpg";
import thirdImg from "../assets/images/three.jpg";
import fourthImg from "../assets/images/four.jpg";
import fifthImg from "../assets/images/five.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import emailjs from "emailjs-com";

const products = [
  {
    id: 1,
    name: "HEAVY DUTY SHREDDER",
    image: firstImg,
    highlights: [
      "Advanced shredding technology",
      "High throughput capacity",
      "Robust construction",
      "Energy efficient operation",
    ],
    description:
      "Industrial-grade shredder designed for efficient biomass waste processing with superior durability and performance.",
  },
  {
    id: 2,
    name: "HEAVY DUTY SECONDARY SHREDDER",
    image: secondImg,
    highlights: [
      "Fine particle size control",
      "Consistent output quality",
      "Low maintenance design",
      "Automated operation",
    ],
    description:
      "Secondary processing unit for achieving precise particle sizes in biomass material preparation.",
  },
  {
    id: 3,
    name: "DEWATERING PRESS UNIT",
    image: thirdImg,
    highlights: [
      "High moisture removal efficiency",
      "Continuous operation capability",
      "Adjustable pressure control",
      "Integrated filtration system",
    ],
    description:
      "Advanced dewatering system for efficient moisture reduction in biomass materials.",
  },
  {
    id: 4,
    name: "THERMO DRYER",
    image: fourthImg,
    highlights: [
      "Precise temperature control",
      "Energy-efficient design",
      "Multiple drying zones",
      "Automatic moisture monitoring",
    ],
    description:
      "State-of-the-art thermal drying solution for optimal moisture content in processed biomass.",
  },
  {
    id: 5,
    name: "BIO COMPACTOR",
    image: fifthImg,
    highlights: [
      "High compression ratio",
      "Uniform density output",
      "Easy maintenance access",
      "Advanced control system",
    ],
    description:
      "Advanced biomass compaction system for producing high-quality fuel briquettes.",
  },
];
const Products = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    product: "",
  });

  const [formStatus, setFormStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("submitting");

    const serviceId = "service_dxk279m";
    const templateId = "template_vse2bqr";
    const userId = "dG_6mQQ1hBEWQVhfm";

    try {
      await emailjs.send(
        serviceId,
        templateId,
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
          product: formData.product,
        },
        userId
      );
      setFormStatus("success");
      setFormData({ name: "", email: "", phone: "", message: "", product: "" });
    } catch (error) {
      console.error("Failed to send message:", error);
      setFormStatus("error");
    }
  };

  const productsOption = [
    "HEAVY DUTY SHREDDER",
    "HEAVY DUTY SECONDARY SHREDDER",
    "DEWATERING PRESS UNIT",
    "THERMO DRYER",
    "BIO COMPACTOR",
  ];

  return (
    <div className="pt-20">
      <section className="relative bg-gradient-to-b from-primary-50 to-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Our Products
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Advanced biomass processing equipment engineered for efficiency
              and sustainability
            </p>
          </motion.div>
        </div>
      </section>

      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {products.map((product, index) => (
              <motion.div
                key={product.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-2xl shadow-lg overflow-hidden group h-full flex flex-col"
              >
                <div className="relative w-full h-64 flex-shrink-0">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                </div>

                <div className="p-6 flex flex-col flex-grow">
                  <h3 className="text-xl font-bold text-gray-900 mb-3 line-clamp-1">
                    {product.name}
                  </h3>
                  <p className="text-gray-600 mb-4 line-clamp-2">
                    {product.description}
                  </p>

                  <div className="space-y-2 mb-6 flex-grow">
                    {product.highlights.map((highlight, i) => (
                      <div key={i} className="flex items-start text-gray-700">
                        <HiChevronRight className="w-5 h-5 text-primary-500 flex-shrink-0 mt-0.5" />
                        <span className="ml-2 line-clamp-1">{highlight}</span>
                      </div>
                    ))}
                  </div>

                  <div className="flex space-x-4 mt-auto">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => navigate(`/products/${product.id}`)}
                      className="flex-1 bg-primary-500 text-white py-2 px-4 rounded-lg hover:bg-primary-600 transition-colors"
                    >
                      Learn More
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                    >
                      <HiOutlineInformationCircle className="w-6 h-6 text-gray-600" />
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-2xl shadow-lg p-8"
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
            Please fill out the form below for any enquiries about our products.
            </h2>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="product"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Select Product
                </label>
                <select
                  id="product"
                  value={formData.productsOption}
                  onChange={(e) =>
                    setFormData({ ...formData, product: e.target.value })
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                  required
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {productsOption.map((product, index) => (
                    <option key={index} value={product}>
                      {product}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  rows={4}
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                  required
                />
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={formStatus === "submitting"}
                className="w-full bg-primary-500 text-white py-3 rounded-lg hover:bg-primary-600 transition-colors disabled:opacity-50"
              >
                {formStatus === "submitting" ? "Sending..." : "Send Message"}
              </motion.button>

              {formStatus === "success" && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="flex items-center text-green-600 bg-green-50 p-4 rounded-lg"
                >
                  <HiCheck className="w-5 h-5 mr-2" />
                  Message sent successfully!
                </motion.div>
              )}

              {formStatus === "error" && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="flex items-center text-red-600 bg-red-50 p-4 rounded-lg"
                >
                  Failed to send the message. Please try again later.
                </motion.div>
              )}
            </form>
          </motion.div>
        </div>
      </div>
    </section>

      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-primary-50 rounded-2xl p-8 md:p-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
                  Need Technical Support?
                </h2>
                <p className="text-gray-600 mb-6">
                  Our expert team is ready to help you with installation,
                  maintenance, and troubleshooting.
                </p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-primary-500 text-white px-8 py-3 rounded-lg hover:bg-primary-600 transition-colors"
                >
                  Contact Support
                </motion.button>
              </div>
              {/* <div className="relative h-64 md:h-full">
                <img
                  src={technicalSupportImage}
                  alt="Technical Support"
                  className="w-full h-full object-cover rounded-xl"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
